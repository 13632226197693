// socketService.js client side

import config from 'react-global-configuration';
import io from 'socket.io-client';


let socket = io.connect(`https://socket.evergreen-restaurant.us/`);


export default socket

