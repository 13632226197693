import React, { Component } from 'react'
import './Landing.css';
import { 
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Fab,
  Tooltip
 } from '@mui/material';
import {
  useParams,
} from "react-router-dom";
import config from 'react-global-configuration';
import { VerticalAlignTop, Share, ZoomIn, ZoomOut } from '@mui/icons-material';
import qrcode from './images/qr.png';
import OrderNow from './images/OrderNow.png';

import AppleOrderNow from './images/AppleOrderNow.png';
import AndroidOrderNow from './images/AndroidOrderNow.png';

import Logo from './images/logo2.png';

import history from '../history' ;

import QRCode from 'qrcode'

import Marquee from "react-fast-marquee";

var moment = require("moment-timezone");

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Partner extends Component {

constructor(props) {
    super(props);

   
    this.state = {
      store: '',
      categorys: {},
      subcategorys: {},
      isLandscape: false,
      showWarning: false,
      shareModal: false,
      phoneInputWarning: '',
      font: 16,
      storeAddress: '2771 Plymouth Rd, Ann Arbor, MI 48105',
      storePhone: '(734)-769-2748',
      storeWIFI: 'Wifi: Evergreen_WIFI',
      storeWIFIPass: 'Password: 7347692748',
      storeName:'Evergreen Restaurant',
      QRCodeUrl:'',
      table: '',
    };
  }




  async componentDidMount(){
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ////console.log(this.props);

    //console.log(this.props.match.params);

    if(this.props.params !== undefined){

       const { store }  = this.props.params;

       const { font }  = this.props.params;


       this.loadCategoryWithSubCategory(store);

       this.setState({
         font: font==undefined?16:font,
         store: store,
       })


       if(store != 'evergreen'){
        this.setState({
          storeName: 'Evergreen Downtown',
          storeAddress: '208 W Liberty St, Ann Arbor, MI 48104',
          storePhone: '(734)-929-5460',
          storeWIFI: 'Wifi: Evergreen_Downtown',
          storeWIFIPass: 'Password: 7349295460',
        })
       }

       this.generateQRCode(store);

    }

    window.addEventListener('resize', (event)=>{
      this.changeHeight();
    });
    
  }


  generateQRCode = async (store)=>{
    let key = `${store}-table`;

    let table = await localStorage.getItem(key);

    var opts = {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.3,
        margin: 1,
        color: {
          dark:"#006622",
          light:"#ffffff"
        }
      }

    //console.log(`https://emenu.evergreen-restaurant.us/store/${this.state.store}/table/${table}`);  

    QRCode.toDataURL(`https://emenu.evergreen-restaurant.us/store/${this.state.store}/table/${table}`,opts,  (err, url) =>{
    
        if (err) 
            console.log(err); 

        this.setState({
            QRCodeUrl: url,
            table: table,
        });
      })
  }


  loadCategoryWithSubCategory = (store) => {
    //alert(config.get('API_URL'));
      this.setState({categorys: {}});
      
      fetch(`${config.get('API_URL')}/menu/categorywithsubcategory?store=${store}`,{
          method: 'GET',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          })

      .then((response) => response.json())
      .then((res) => {
        //alert('here');
        if(res.success === true){
          console.log(res.row);
          this.setState({categorys: res.row}, ()=>this.changeHeight())
        }else{
          //console.log(res.message);
        }

      })
      .catch((err)=>{
         //console.log( err );
      });
    }


  adjustLayout = ()=>{
    if(this.isPortrait()){
      this.setState({isLandscape: true})
    }else if(this.isLandscape()){
      this.setState({isLandscape: false})
    }
  }

  changeHeight = async ()=> {
    //const screenOrientation = this.useScreenOrientation()

    //console.log(screenOrientation);
    await this.adjustLayout();

    var content = document.querySelector('#parchment');
    var container = document.querySelector('#contain');

    let minHeight = window.innerWidth && document.documentElement.clientWidth ? 
                    Math.max(window.innerWidth, document.documentElement.clientWidth) : 
                    window.innerWidth || 
                    document.documentElement.clientWidth || 
                    document.getElementsByTagName('body')[0].clientWidth;


    minHeight = Math.max(window.screen.height, minHeight);

    // SVG feTurbulence can modify all others elements, that's why "parchment" is in absolute position.
    // so for a better effect, absolute height is defined by his content.
    content.style.height = Math.max(minHeight, container.offsetHeight)+'px';

    if(window.innerWidth<=980)
      this.setState({showWarning: false})
    else
      this.setState({showWarning: true})

  }


  isPortrait = ()=> {
    return window.innerHeight >= window.innerWidth
  }

  isLandscape =()=> {
    return window.innerHeight <= window.innerWidth
  }


  genrateCategoryData = () => {
      let categoryButtom = [];
      this.state.subcategorys = {};

      for(let i=0; i<this.state.categorys.length; i++){

        if(categoryButtom.indexOf(this.state.categorys[i].CATEGORY) === -1)
          categoryButtom.push(this.state.categorys[i].CATEGORY);

        if(this.state.subcategorys[this.state.categorys[i].CATEGORY] === undefined){
          this.state.subcategorys[this.state.categorys[i].CATEGORY] = [this.state.categorys[i].CNAME.trim()];
        }else{
          if(this.state.subcategorys[this.state.categorys[i].CATEGORY].indexOf(this.state.categorys[i].CNAME.trim())===-1)
            this.state.subcategorys[this.state.categorys[i].CATEGORY].push(this.state.categorys[i].CNAME.trim());
        }

      }

      // console.log(this.state.categorys);

      //  console.log(categoryButtom);
      //  console.log(this.state.subcategorys);

      return categoryButtom;
  }


  genrateCategoryButton = (buttons) => {

    return buttons.map((item)=><div className="my-card"><Grid key={item} style={{lineHeight: window.innerWidth<450?'2em':'4em', fontSize:window.innerWidth<450?(parseInt(this.state.font)+35)+'px':(parseInt(this.state.font)+45)+'px'}} container xs={12} justifyContent="center" className="inkTitleCat" onClick={()=>history.push(`/store/${this.state.store}/category/${item}/font/${this.state.font}`)}><p>{item}</p></Grid>{this.state.subcategorys[item].length >0?this.state.subcategorys[item].map(subitem =><Grid xs={12} className="my-card-p" container justifyContent="center" onClick={()=>history.push(`/store/${this.state.store}/category/${item}/subcategory/${subitem}/font/${this.state.font}`)} style={{fontSize: window.innerWidth<450?(parseInt(this.state.font)+13)+'px':(parseInt(this.state.font)+18)+'px', textAlign:'center'}}>{subitem==='N/A'?null:subitem}</Grid>): null}</div>);
  }


  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  scrollLocation = ()=>{
      var doc = document.documentElement;
      var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
      var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

      return {'left': left, 'top': top};
    }

   shareMenu = ()=>{
     this.setState({
       shareModal:this.state.shareModal?false:true,
       phoneInputWarning: '',
     });
   }

  debounceAction = (func, timeout = 1500) =>{
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

   shareMenuActive = async ()=>{
     //console.log(this.state.phone);

     let table = await localStorage.getItem(`${this.state.store}-table`);

     if(this.phonenumber(this.state.phone)){
        fetch(`${config.get('API_URL')}/menu/sharemenu?phone=${this.state.phone}&store=${this.state.store}&table=${table}`,{
            method: 'GET',
            headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            })

        .then((response) => response.json())
        .then((res) => {
          //alert('here');
          if(res.success === true){

            let seconds = 3;

            this.setState({phoneInputWarning: <p style={{color: 'green'}}>Successs, you will receive the link soon! {seconds}s</p>});

            let b = setInterval(()=>{
              seconds = seconds - 1;
              this.setState({phoneInputWarning: <p style={{color: 'green'}}>Successs, you will receive the link soon! {seconds}s</p>})
            } ,1000);
            //console.log(res.row);
            setTimeout( () => {
                this.shareMenu();
                clearInterval(b);
            }, 3000);

          }else{
            //console.log(res.message);
            this.setState({phoneInputWarning: <p style={{color: 'red'}}>{res.messsage}</p>});
          }

        })
        .catch((err)=>{
           //console.log( err );
           this.setState({phoneInputWarning: <p style={{color: 'red'}}>Internet error, pleasae try again later!</p>});
        });


     }else{
       this.setState({phoneInputWarning: <p style={{color: 'red'}}>Please enter a valid phone number, eg. 7340000000</p>});
     }

   }


   phonenumber = (inputtxt)=>{
      var phoneno = /^\d{10}$/;
      if(inputtxt.match(phoneno)){
         return true;
      }else{
         return false;
      }
    }

   adjustSize = (operation)=>{
     if(this.state.font < 0 && operation == '-')
       return;

     let newFont = 0;

     if(operation == '-'){
       newFont = parseInt(this.state.font) - 5;
       this.setState({font: parseInt(this.state.font) - 5})
     }else{
       newFont = parseInt(this.state.font) + 5;
       this.setState({font: parseInt(this.state.font) + 5})
     }

     this.changeHeight();

     history.push(`/store/${this.state.store}/landing/font/${newFont}`)


   }

   openTableOrder = async ()=>{

    let tabelOrdering = {
      billsbeergarden: 'https://evergreen-downtown.square.site/s/order?customer_seat_id=11ed1a585b710306bbaafe6604b81a35',
      default: 'https://evergreen-downtown.square.site/s/order',
    }

      let key = `${this.state.store}-partner`;

      let partner = await localStorage.getItem(key);

      await localStorage.removeItem(key);
     
      if(tabelOrdering.hasOwnProperty(partner)){
        window.location.href = tabelOrdering[partner];
      }else if(this.state.store == 'evergreen'){
        window.location.href = 'https://evergreen-restaurant.us/online';
      }else{
        window.location.href = tabelOrdering['default'];
      }

   }


  checkCurrentBusinessHours = async ()=>{
    var curTime = new Date();

    let curLocation = 1;

    if(this.state.store == 'downtown')
      curLocation = 2;


    await fetch(`${config.get('API_URL')}/payment/token?orderTime=${curTime}&curLocation=${curLocation}`,{
      method: 'GET',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      })

    .then((response) => response.json())
    .then((res) => {

      if(res.success === true){
        //console.log(res);
        this.openTableOrder();

      }else{
        alert('Sorry, out of business hours.'); 
      }

    })
    .catch((err)=>{
      alert('Sorry, internet is available, please try again.');
  })


  }


render() {
  return (

          <Container>
              
              <Grid id="parchment" style={{width: window.innerWidth<1300?'100%':'95%', padding: window.innerWidth<1300?'1em':'4em'}}></Grid>
              <Grid id="contain">

                <img
                  src={Logo}
                  style={{position: 'fixed', width: '18%', bottom: '6%', right:'10%', opacity: 0.2, pointerEvents: 'none'}}
                /> 

                <Grid container justifyContent="center" xs={12} className="storeTitle" style={{lineHeight: '1.5em', padding: '60px', fontSize:window.innerWidth<450?'2em':'3.5em'}}>Order with Neighbor</Grid>
                      
                <Grid container justifyContent="center" xs={12} className="infoDiv">{this.state.storeName}</Grid>      
                <Grid container justifyContent="center" xs={12} className="infoDiv">{this.state.storeAddress}</Grid>
                <Grid container justifyContent="center" xs={12} className="infoDiv">{this.state.storePhone}</Grid>
                <Grid container justifyContent="center" xs={12} className="infoDiv"><a href="https://www.google.com/maps/place/Evergreen+Downtown/@42.279746,-83.7525697,17z/data=!3m1!4b1!4m5!3m4!1s0x883caf527a052447:0x44ca332ac3fc301a!8m2!3d42.2797421!4d-83.7503757" target="_blank">Get Direction</a></Grid>


                {/* <Fab color='inherit' variant='extended' size={window.innerWidth<450?'small':'large'} style={{position: 'fixed', top: 110, right: 40,}} onClick={() => this.adjustSize('-')}>
                    <ZoomOut/>
                </Fab>

                <Fab color='inherit' variant='extended' size={window.innerWidth<450?'small':'large'} style={{position: 'fixed', top: 190, right: 40,}} onClick={() => this.adjustSize('+')}>
                    <ZoomIn/>
                </Fab> */}

                <div className="my-card">
                    <Grid style={{paddingBottom: 0, padding: '80px', fontSize:window.innerWidth<450?(parseInt(this.state.font)+25)+'px':(parseInt(this.state.font)+35)+'px'}} container xs={12} justifyContent="center" className="inkTitleCat" onClick={()=>this.checkCurrentBusinessHours()}>
                        <b>E-Menu & Ordering</b>
                    </Grid>
                    Click here to view E-Menu and place an order online. You can come to pick it up in about 20 minutes. Feel free to call us if you have any questions.
                </div>


                    <Marquee gradient={false} style={{marginTop: '20px', duration: '30s !important'}}>
                          <img
                          src={require('./images/menu/1.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/2.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/3.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/4.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/5.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/6.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/7.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/8.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/9.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/10.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/11.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />
                          <img
                          src={require('./images/menu/12.jpeg')}
                          style={{borderRadius: 20,  height: 180}}
                          />

                      </Marquee>
               


                {window.innerWidth>1130?<img
                  src={OrderNow}
                  alt='OrderNow'
                  loading="lazy"
                  className="button"
                  style={{maxWidth: 150, marginTop: 50, marginLeft: 'auto', marginRight: 'auto'}}
                  onClick = {()=>localStorage.getItem(`${this.state.store}-table`) == undefined?window.open("http://evergreen-restaurant.us/online"):this.checkCurrentBusinessHours()}
                 />:<Grid container justifyContent="center" xs={12} style={{marginTop: 50}}><img
                  src={AppleOrderNow}
                  alt='AppleOrderNow'
                  loading="lazy"
                  className="button"
                  style={{maxWidth: 150, height: 65}}
                  onClick = {()=>window.open("https://apps.apple.com/us/app/evergreen-restaurant/id1333691226")}
                 />
                 <img
                  src={AndroidOrderNow}
                  alt='AndroidOrderNow'
                  loading="lazy"
                  className="button"
                  style={{maxWidth: 150, height: 65}}
                  onClick = {()=>window.open("https://play.google.com/store/apps/details?id=com.evergreenorientalinc.evergreenrestaurant")}
                 /></Grid>}
              
                <Grid container justifyContent="center" xs={12} className="infoDiv">Get a 15% off coupon when you sign up with us.</Grid>
                
              </Grid>

                <Button variant="contained" style={{color: "white", backgroundColor:"gray", border:"1px solid gray", position: 'fixed', bottom: 0, left:'83%'}} onClick={() => this.shareMenu()}>
                  <Share/>
                </Button>


              <Dialog open={this.state.shareModal} onClose={()=>this.shareMenu()}>
                <DialogTitle>Share</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To share our menu with your friends or send it to your mobile devices, please enter your mobile number below. Thank you!
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    id="phone"
                    label="Phone"
                    type="number"
                    fullWidth
                    color={this.state.phoneInputColor}
                    variant="standard"
                    onChange = {(e)=>{this.setState({phone: e.target.value, phoneInputWarning: ''})}}
                  />
                  {this.state.phoneInputWarning}
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>this.setState({shareModal: false})}>Cancel</Button>
                  <Button onClick={this.debounceAction(()=>this.shareMenuActive())}>Submit</Button>
                </DialogActions>
              </Dialog>


               

          
    </Container>

  );
}

}
export default withParams(Partner);
