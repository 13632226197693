import React from 'react';
import { render } from "react-dom";
import './index.css';
import App from './App/App';
import Home from './Home/Home';
import SetTable from './SetTable/SetTable';
import Landing from './Landing/Landing';
import Partner from './Landing/Partner';
import SetPartner from './SetPartner/SetPartner';
import reportWebVitals from './reportWebVitals';

import { Switch, Route, Router } from "react-router-dom";
import config from 'react-global-configuration';
// import { Buffer } from 'buffer';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store from './store'; // Import the Redux store directly
import history from './history'; // Import history directly
import 'bootstrap/dist/css/bootstrap.min.css';
import smoothscroll from 'smoothscroll-polyfill';
import SocketListener from './Socket/SocketListener';

// kick off the polyfill!
smoothscroll.polyfill();

// global.Buffer = Buffer;


config.set({
  //API_URL: 'http://127.0.0.1:3200',
  SOCKET_URL: 'https://socket.evergreen-restaurant.us',
  API_URL: 'https://www.evergreen-restaurant.us/server',
  myCart: 'evergreenCart',
});


const rootElement = document.getElementById("root");
render(
	<Provider store={store}>
	  <ConnectedRouter history={history}>
	  	<SocketListener /> {/* Include SocketListener component */}
		<Switch>
		  <Route exact path="/store/:store/table/:table" component={SetTable} />
		  <Route exact path="/store/:store/landing/partner/:partner" component={SetPartner} />
		  <Route exact path="/store/:store/landing/partner" component={Partner} />
		  <Route exact path="/store/:store/landing/font/:font" component={Landing} />
		  <Route exact path="/store/:store/landing" component={Landing} />
		  <Route exact path="/store/:store/category/:category/subcategory/:subcategory/font/:font" component={App} />
		  <Route exact path="/store/:store/category/:category/subcategory/:subcategory" component={App} />
		  <Route exact path="/store/:store/category/:category/font/:font" component={App} />
		  <Route exact path="/store/:store/category/:category" component={App} />
		  <Route exact path="/store/:store/font/:font" component={Home} />
		  <Route exact path="/store/:store" component={Home} />
		  <Route exact path="/" component={Home} /> {/* This should match your root route */}
		</Switch>
	  </ConnectedRouter>
	</Provider>,
	rootElement
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
