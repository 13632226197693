import * as types from './types';
import config from 'react-global-configuration';
import socket from '../Socket/socket'; // Import your socket service


var CryptoJS = require("crypto-js");


/*
Action Creators
*/

export function loadCart(data){
  return {
    type: types.LOAD_CART, 
    cart: data,
  };
}

/*
dispatch the actionCreators 
*/

export function loadShoppingCart(upload){
  //alert('load orders');
  return async function(dispatch, getState) {

    console.log('redux loadShoppingCart');


    let curLocation = await localStorage.getItem('curLocation');

    let selectedTable = await localStorage.getItem('selectedTable');

    let cartID = `${curLocation}-${selectedTable}`;

    let data = await localStorage.getItem(cartID);   //cartID?   localStorage.getItem(curLocation)

    if(data !== null && data.length !== 0){
    // Decrypt 
      let bytes  = CryptoJS.AES.decrypt(data.toString(), config.get('myCart'));
      let plaintext = bytes.toString(CryptoJS.enc.Utf8);

      let cartData = JSON.parse(plaintext);

      //console.log(cartData);

      cartData = {
        newCart: cartData.newCart,
        completeCart: cartData.completeCart,
        totalNewItem: calculateTotalItems(cartData.newCart),
        totalKitchenItem: calculateTotalItems(cartData.completeCart),
        totalNewPrice: calculateTotalPrice(cartData.newCart),
        totalKitchenPrice: calculateTotalPrice(cartData.completeCart),
      }

      //console.log(cartData);

      if(upload == true)
        upLoadCart(cartID, plaintext);

      dispatch(loadCart({cartData}));
    }else{

      let emptyCart = JSON.stringify({
        newCart: [],
        completeCart: [],
        totalNewItem: 0,
        totalKitchenItem: 0,
        totalNewPrice: 0,
        totalKitchenPrice: 0
      });

      let cipherCART = CryptoJS.AES.encrypt(emptyCart, config.get('myCart'));

      await localStorage.setItem(cartID, cipherCART);

      dispatch(loadShoppingCart(true));
  }
}
}


export function joinSocketTable(){
  return async function(dispatch, getState) {

    console.log('redux joinSocketTable');

    let curLocation = await localStorage.getItem('curLocation');

    let selectedTable = await localStorage.getItem('selectedTable');

    let cartID = `${curLocation}-${selectedTable}`;

    if(selectedTable){
      //console.log('Socket Client join table!');
      socket.emit('joinTable', cartID);
      dispatch(getLatestCartforThisTable());
    }
  }
}

export  function getLatestCartforThisTable(){
  return async function(dispatch, getState) {

    console.log('redux getLatestCartforThisTable');
    
    let curLocation = await localStorage.getItem('curLocation');

    let selectedTable = await localStorage.getItem('selectedTable');

    let cartID = `${curLocation}-${selectedTable}`;

    //console.log('getLatestCartforThisTable: ' + cartID);

    fetch(`${config.get('API_URL')}/menu/latestcart?cartID=${cartID}`,{
        method: 'GET',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        })

    .then((response) => response.json())
    .then((res) => {
      //alert('here');
      //console.log(res);
      if(res.success === true){
        let cipherCART = CryptoJS.AES.encrypt(res.cart, config.get('myCart'));
        localStorage.setItem(cartID, cipherCART);

        dispatch(loadShoppingCart(false));

      }else{
        console.log(res.message);
      }

    })
    .catch((err)=>{
      //console.log( err );
    });

  }

}


export  function saveLatestCartData(cartData){
  return async function(dispatch, getState) {

    console.log('redux saveLatestCartData');

    let curLocation = await localStorage.getItem('curLocation');

    let selectedTable = await localStorage.getItem('selectedTable');

    let cartID = `${curLocation}-${selectedTable}`;

    let cipherCART = CryptoJS.AES.encrypt(cartData, config.get('myCart'));

    localStorage.setItem(cartID, cipherCART);

    //console.log('saveLatestCartData success!');

    dispatch(loadShoppingCart(false));
  }

}


function calculateTotalItems(cartData){

  let totalItems = 0;

  for(let i=0; i<cartData.length; i++){
    let thisItemTotal = cartData[i].number;
    totalItems += parseInt(thisItemTotal);
  }

  return parseInt(totalItems);
}

function calculateTotalPrice(cartData){

  let totalPrice = 0;

  for(let i=0; i<cartData.length; i++){
    let thisItemTotal = cartData[i].number;
    let thisItemPrice = cartData[i].price;
    totalPrice += parseFloat(thisItemTotal) * parseFloat(thisItemPrice);
  }

  return totalPrice.toFixed(2);
}


function upLoadCart(tableID, cartData) {

  let data = {
    tableID: tableID,
    cartData: cartData,
  }
  
  fetch(`${config.get('API_URL')}/menu/dineinorder`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data) // Include data in the request body
  })
  .then((response) => response.json())
  .then((res) => {
    //this.toastNotify('here');
    //toast(`${res.message}!`);
    console.log(res.message);
  })
  .catch((err) => {
    //toast(`${err}!`);
    console.log(err);
  })

}






