import * as types from '../Actions/types';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  shoppingCart: [],
});

//order reducer
export function cart(state = initialState, action = {}) {

  switch (action.type) {
    
    case types.LOAD_CART:
      return state.merge({
        shoppingCart: action.cart.cartData,
      });


    default:
      return state;
  }
}
