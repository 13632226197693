import React, { Component } from 'react'
import './Home.css';
import { 
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Fab,
  Tooltip,
  Modal,
  Box,
  SpeedDial,
  SpeedDialAction,
  Badge,
 } from '@mui/material';
import {
  useParams,
} from "react-router-dom";
import config from 'react-global-configuration';
import { ShoppingCartCheckout, MenuOpen, Undo, QuestionAnswer, ContactSupport,  Paid, Restaurant, ReceiptLong, LocalDrink, RoomService, Share, MenuBook, ZoomOut, ZoomIn, KeyboardArrowUp, KeyboardArrowDown, Publish, VerticalAlignBottom, VerticalAlignTop } from '@mui/icons-material';


import { Home as HomeIcon } from '@mui/icons-material';
import qrcode from './images/qr.png';
import OrderNow from './images/OrderNow.png';

import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppleOrderNow from './images/AppleOrderNow.png';
import AndroidOrderNow from './images/AndroidOrderNow.png';

import Logo from './images/logo2.png';

import history from '../history' ;

import { connect } from 'react-redux';
import * as  actionCreators from '../Actions/index';
import { bindActionCreators } from 'redux';


import CartModal from '../CartModal/CartModal';


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Home extends Component {

constructor(props) {
    super(props);

   
    this.state = {
      store: '',
      categorys: {},
      subcategorys: {},
      isLandscape: false,
      showWarning: false,
      shareModal: false,
      phoneInputWarning: '',
      font: 16,
      storeAddress: '2771 Plymouth Rd, Ann Arbor, MI 48105',
      storePhone: '(734)-769-2748',
      storeWIFI: 'Wifi: Evergreen_WIFI',
      storeWIFIPass: 'Password: 7347692748',
      promoModal: false,
      promoImageUrl: '',
      promoTitle: '',
    };
  }




  componentDidMount(){
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ////console.log(this.props);

    //console.log(this.props.match.params);

  this.props.actions.joinSocketTable();



    if(this.props.params !== undefined){

       const { store }  = this.props.params;

       const { font }  = this.props.params;


       this.loadCategoryWithSubCategory(store);

       this.setState({
         font: font==undefined?16:font,
         store: store,
       })

       let curLocation = 0;

      if(store == "evergreen")
        curLocation = 1;
      else if(store == "downtown")
        curLocation = 2;

      localStorage.setItem('curLocation', curLocation);


       if(store != 'evergreen'){
        this.setState({
          storeAddress: '208 W Liberty St, Ann Arbor, MI 48104',
          storePhone: '(734)-929-5460',
          storeWIFI: 'Wifi: Evergreen_Downtown',
          storeWIFIPass: 'Password: 7349295460',
        })
       }

       this.checkSpecialPromo(store);

    }

    window.addEventListener('resize', (event)=>{
      this.changeHeight();
    });
    
  }


  checkSpecialPromo = (store) =>{

    let curLocation = 0;

    if(store == "evergreen")
      curLocation = 1;
    else if(store == "downtown")
      curLocation = 2;


    fetch(`${config.get('API_URL')}/ads?location=${curLocation}`,{
          method: 'GET',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          })

      .then((response) => response.json())
      .then((res) => {
        //this.toastNotify('here');
        if(res.success === true){
            this.setState({
              promoModal: true,
              promoImageUrl: res.imageUrl,
              promoTitle: res.title,
            });

        }else{
            this.setState({
              promoModal: false
            })

        }

      })
      .catch((err)=>{
         //this.toastNotify("Internet not available, please try again later.", "error");
      })

  }

  handleAdClose = ()=>{
    this.setState({
      promoModal: false,
    })
  }


  loadCategoryWithSubCategory = (store) => {
    //alert(config.get('API_URL'));
      this.setState({categorys: {}});
      
      fetch(`${config.get('API_URL')}/menu/categorywithsubcategory?store=${store}`,{
          method: 'GET',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          })

      .then((response) => response.json())
      .then((res) => {
        //alert('here');
        if(res.success === true){
          //console.log(res.row);
          this.setState({categorys: res.row}, ()=>this.changeHeight())
        }else{
          //console.log(res.message);
        }

      })
      .catch((err)=>{
         //console.log( err );
      });
    }


  adjustLayout = ()=>{
    if(this.isPortrait()){
      this.setState({isLandscape: true})
    }else if(this.isLandscape()){
      this.setState({isLandscape: false})
    }
  }

  changeHeight = async ()=> {
    //const screenOrientation = this.useScreenOrientation()

    //console.log(screenOrientation);
    await this.adjustLayout();

    var content = document.querySelector('#parchment');
    var container = document.querySelector('#contain');

    let minHeight = window.innerWidth && document.documentElement.clientWidth ? 
                    Math.max(window.innerWidth, document.documentElement.clientWidth) : 
                    window.innerWidth || 
                    document.documentElement.clientWidth || 
                    document.getElementsByTagName('body')[0].clientWidth;


    minHeight = Math.max(window.screen.height, minHeight);

    // SVG feTurbulence can modify all others elements, that's why "parchment" is in absolute position.
    // so for a better effect, absolute height is defined by his content.
    content.style.height = Math.max(minHeight, container.offsetHeight)+'px';

    if(window.innerWidth<=980)
      this.setState({showWarning: false})
    else
      this.setState({showWarning: true})

  }


  isPortrait = ()=> {
    return window.innerHeight >= window.innerWidth
  }

  isLandscape =()=> {
    return window.innerHeight <= window.innerWidth
  }


  genrateCategoryData = () => {
      let categoryButtom = [];
      this.state.subcategorys = {};

      for(let i=0; i<this.state.categorys.length; i++){

        if(categoryButtom.indexOf(this.state.categorys[i].CATEGORY) === -1)
          categoryButtom.push(this.state.categorys[i].CATEGORY);

        if(this.state.subcategorys[this.state.categorys[i].CATEGORY] === undefined){
          this.state.subcategorys[this.state.categorys[i].CATEGORY] = [this.state.categorys[i].CNAME.trim()];
        }else{
          if(this.state.subcategorys[this.state.categorys[i].CATEGORY].indexOf(this.state.categorys[i].CNAME.trim())===-1)
            this.state.subcategorys[this.state.categorys[i].CATEGORY].push(this.state.categorys[i].CNAME.trim());
        }

      }

      // console.log(this.state.categorys);

      //  console.log(categoryButtom);
      //  console.log(this.state.subcategorys);

      return categoryButtom;
  }


  genrateCategoryButton = (buttons) => {

    return buttons.map((item, index)=><div key={index} className="my-card"><Grid key={item} style={{ padding: '80px', fontSize:window.innerWidth<450?(parseInt(this.state.font)+25)+'px':(parseInt(this.state.font)+35)+'px'}} container xs={12} justifyContent="center" className="inkTitleCat" onClick={()=>history.push(`/store/${this.state.store}/category/${item}/font/${this.state.font}`)}><p>{item}</p></Grid>{this.state.subcategorys[item].length >0?this.state.subcategorys[item].map((subitem, subkey )=><Grid key={subkey} xs={12} className="my-card-p" container justifyContent="center" onClick={()=>history.push(`/store/${this.state.store}/category/${item}/subcategory/${subitem}/font/${this.state.font}`)} style={{fontSize: window.innerWidth<450?(parseInt(this.state.font)+13)+'px':(parseInt(this.state.font)+18)+'px', textAlign:'center'}}>{subitem==='N/A'?null:subitem}</Grid>): null}</div>);
  }


  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  scrollLocation = ()=>{
      var doc = document.documentElement;
      var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
      var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

      return {'left': left, 'top': top};
    }

   shareMenu = ()=>{
     this.setState({
       shareModal:this.state.shareModal?false:true,
       phoneInputWarning: '',
     });
   }

  debounceAction = (func, timeout = 1500) =>{
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

   shareMenuActive = async ()=>{
     //console.log(this.state.phone);

     let table = await localStorage.getItem(`${this.state.store}-table`);

     if(this.phonenumber(this.state.phone)){
        fetch(`${config.get('API_URL')}/menu/sharemenu?phone=${this.state.phone}&store=${this.state.store}&table=${table}`,{
            method: 'GET',
            headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            })

        .then((response) => response.json())
        .then((res) => {
          //alert('here');
          if(res.success === true){

            let seconds = 3;

            this.setState({phoneInputWarning: <p style={{color: 'green'}}>Successs, you will receive the link soon! {seconds}s</p>});

            let b = setInterval(()=>{
              seconds = seconds - 1;
              this.setState({phoneInputWarning: <p style={{color: 'green'}}>Successs, you will receive the link soon! {seconds}s</p>})
            } ,1000);
            //console.log(res.row);
            setTimeout( () => {
                this.shareMenu();
                clearInterval(b);
            }, 3000);

          }else{
            //console.log(res.message);
            this.setState({phoneInputWarning: <p style={{color: 'red'}}>{res.messsage}</p>});
          }

        })
        .catch((err)=>{
           //console.log( err );
           this.setState({phoneInputWarning: <p style={{color: 'red'}}>Internet error, pleasae try again later!</p>});
        });


     }else{
       this.setState({phoneInputWarning: <p style={{color: 'red'}}>Please enter a valid phone number, eg. 7340000000</p>});
     }

   }


   phonenumber = (inputtxt)=>{
      var phoneno = /^\d{10}$/;
      if(inputtxt.match(phoneno)){
         return true;
      }else{
         return false;
      }
    }

   adjustSize = (operation)=>{
     if(this.state.font < 0 && operation == '-')
       return;

     let newFont = 0;

     if(operation == '-'){
       newFont = parseInt(this.state.font) - 5;
       this.setState({font: parseInt(this.state.font) - 5})
     }else{
       newFont = parseInt(this.state.font) + 5;
       this.setState({font: parseInt(this.state.font) + 5})
     }

     this.changeHeight();

     history.push(`/store/${this.state.store}/font/${newFont}`)


   }

   openTableOrder = ()=>{

    window.location.href = "http://evergreen-restaurant.us/online";
    return;

    let tabelOrdering = {
      A1: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl39o0b7hehozjizmb3tt1',
      A2: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl39o0bf7b40ukl02j2qat',
      A3: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl39o0bk4nxj0g6t7kmjl1',
      A4: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl39o0bofb2bvmfrk2kyat',
      A5: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl39o0bskjgr91slnzll0l',
      A6: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl39o0bwop445d5qjlo49x',
      A7: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl39o0c0rqze8q4zcyh38l',
      A8: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl3c2ky3gnru7we0383hgu',
      A9: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl3c2kyfyi84v47c8a71v2',
      A10: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl3c2kywids4k0y7vtry9a',
      A11: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl3c2kz14wmyfkh84pi4a6',
      A12: 'https://square.online/app/store/api/seat/2cc5d2:1ho18s6i8il3:127dl3c2kz66l8kmpe4jc0nji',
      default: 'https://evergreen-downtown.square.site/s/order',
    }

      let key = `${this.state.store}-table`;

      let table = localStorage.getItem(key);
     
      if(tabelOrdering.hasOwnProperty(table)){
        window.location.href = tabelOrdering[table];
      }else{
        window.location.href = tabelOrdering['default'];
      }

   }

   goToLandingPage = ()=>{

    history.push(`/store/${this.state.store}/landing`);

   }

   serviceAction=(request)=>{
    console.log(request);

    let store = this.state.store;
    let table = this.state.selectedTable;

    if(!store || !table || !request){
      toast(`Woops, something went wrong! Please try again later.`);
      return;
    }

    fetch(`${config.get('API_URL')}/menu/serviceAction?store=${store}&table=${table}&request=${request}`,{
        method: 'GET',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        })

    .then((response) => response.json())
    .then((res) => {
      //this.toastNotify('here');
      toast(`${res.message}!`);

    })
    .catch((err)=>{
      toast(`${err}!`);
    })


  }


render() {
  return (

          <Container>

          <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition= {Bounce}
          />
              
              <Grid id="parchment" style={{width: window.innerWidth<1300?'100%':'95%', padding: window.innerWidth<1300?'1em':'4em'}}></Grid>
              <Grid id="contain">

                <img
                  src={Logo}
                  style={{position: 'fixed', width: '18%', bottom: '6%', right:'10%', opacity: 0.2, pointerEvents: 'none'}}
                /> 

                <Grid container justifyContent="center" className="storeTitle" style={{lineHeight: '1.5em', padding: '30px', fontSize:window.innerWidth<450?'2em':'3.5em'}}>{this.capitalizeFirstLetter(this.state.store)} E-Menu</Grid>
                      
                <Grid container justifyContent="center" xs={12} className="infoDiv">{this.state.storeAddress}</Grid>
                <Grid container justifyContent="center" xs={12} className="infoDiv">{this.state.storePhone}</Grid>

                <div className={this.state.isLandscape?"single-my-card-wrapper":"my-card-wrapper"}>{this.genrateCategoryButton(this.genrateCategoryData())}</div>
                
                <Fab color='inherit' variant='extended' size={window.innerWidth<450?'small':'large'} style={{position: 'fixed', top: 30, left: 40,}} onClick={() => this.goToLandingPage()}>
                    <HomeIcon/>
                </Fab>

                <Fab color='inherit' variant='extended' size={window.innerWidth<450?'small':'large'} style={{position: 'fixed', top: 30, right: 40,}} onClick={() => this.adjustSize('-')}>
                    <ZoomOut/>
                </Fab>

                <Fab color='inherit' variant='extended' size={window.innerWidth<450?'small':'large'} style={{position: 'fixed', top: 110, right: 40,}} onClick={() => this.adjustSize('+')}>
                    <ZoomIn/>
                </Fab>

                <CartModal></CartModal>


                {/* {this.state.store=='downtown'&& localStorage.getItem(`${this.state.store}-table`) != undefined?<Fab color='inherit' variant='extended' size={window.innerWidth<450?'small':'large'} style={{position: 'fixed', top: '3%', left:'20%', right: '20%', width:'60%', opacity: 0.9}} onClick={() => this.openTableOrder()}>
                    <b>Click here to order now(Table: {localStorage.getItem(`${this.state.store}-table`)})</b>
                </Fab>:this.state.store=='downtown'?<Fab color='inherit' variant='extended' size={window.innerWidth<450?'small':'large'} style={{position: 'fixed', top: '3%', left:'20%', right: '20%', width:'60%', opacity: 0.9}} onClick={() => this.openTableOrder()}>
                    <b>Click here to order now</b>
                </Fab>:null} */}


                <img
                  src={OrderNow}
                  alt='OrderNow'
                  loading="lazy"
                  className="button"
                  style={{maxWidth: 150, marginTop: 50, marginLeft: 'auto', marginRight: 'auto'}}
                  onClick = {()=>console.log("Order Now pressed!")}
                 />

                {/* {window.innerWidth>1130?<img
                  src={OrderNow}
                  alt='OrderNow'
                  loading="lazy"
                  className="button"
                  style={{maxWidth: 150, marginTop: 50, marginLeft: 'auto', marginRight: 'auto'}}
                  onClick = {()=>console.log("Order Now pressed!")}
                 />: */}
                 {/* <Grid container justifyContent="center" xs={12} style={{marginTop: 50}}><img
                  src={AppleOrderNow}
                  alt='AppleOrderNow'
                  loading="lazy"
                  className="button"
                  style={{maxWidth: 150, height: 65}}
                  onClick = {()=>window.open("https://apps.apple.com/us/app/evergreen-restaurant/id1333691226")}
                 />
                 <img
                  src={AndroidOrderNow}
                  alt='AndroidOrderNow'
                  loading="lazy"
                  className="button"
                  style={{maxWidth: 150, height: 65}}
                  onClick = {()=>window.open("https://play.google.com/store/apps/details?id=com.evergreenorientalinc.evergreenrestaurant")}
                 /></Grid> */}
              
                <Grid container justifyContent="center" xs={12} className="infoDiv">{this.state.storeWIFI}</Grid>
                <Grid container justifyContent="center" xs={12} className="infoDiv">{this.state.storeWIFIPass}</Grid>
                <Grid container justifyContent="center" xs={12} className="infoDiv">* An 18% gratuity will be added and a maximum of 4 credit card transactions are allowed for a party of 5 and more.</Grid>

              </Grid>

                <Button variant="contained" style={{color: "white", backgroundColor:"gray", border:"1px solid gray", position: 'fixed', bottom: 0, left:'83%'}} onClick={() => this.shareMenu()}>
                  <Share/>
                </Button>


              <Dialog open={this.state.shareModal} onClose={()=>this.shareMenu()}>
                <DialogTitle>Share</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To share our menu with your friends or send it to your mobile devices, please enter your mobile number below. Thank you!
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    id="phone"
                    label="Phone"
                    type="number"
                    fullWidth
                    color={this.state.phoneInputColor}
                    variant="standard"
                    onChange = {(e)=>{this.setState({phone: e.target.value, phoneInputWarning: ''})}}
                  />
                  {this.state.phoneInputWarning}
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>this.setState({shareModal: false})}>Cancel</Button>
                  <Button onClick={this.debounceAction(()=>this.shareMenuActive())}>Submit</Button>
                </DialogActions>
              </Dialog>

              <Modal
                open={this.state.promoModal}
                onClose={this.handleAdClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Grid container onClick={this.handleAdClose}>
                  <img src={this.state.promoImageUrl} 
                    style={{maxHeight: window.innerHeight, maxWidth: window.innerWidth, marginLeft: 'auto', marginRight: 'auto'}}
                  ></img>
                  
                </Grid>
              </Modal>


               

          
    </Container>

  );
}

}

//Inject success and all action creators (actionCreators, ...) as actions
function mapStateToProps(state) {
  //console.log(state.cart.shoppingCart);
  return { shoppingCart: state.cart.shoppingCart}
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actionCreators, dispatch) }
}

export default withParams(connect(mapStateToProps, mapDispatchToProps)(Home))


