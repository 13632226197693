import React, { Component } from 'react';
import { 
    Container,
    Grid,
    Modal,
    Box,
    Fab,
    Badge,
    Tabs,
    Tab,
    Button,
    SpeedDial,
    SpeedDialAction,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup,
    TextField,
    Alert,
    Icon,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    List,
    ListItem,
    Divider,
    InputAdornment
   } from '@mui/material';
import { Outbox, Email, Print, Warning, AddCircle, ExpandMore, Delete, Check, ContactSupport, Restaurant, ReceiptLong, LocalDrink, RoomService, DeleteForever, ShoppingCartCheckout, RemoveCircleOutline, AddCircleOutline} from '@mui/icons-material';

import { connect } from 'react-redux';
import * as  actionCreators from '../Actions/index';
import { bindActionCreators } from 'redux';

import emptyImage from './images/empty.png'; // Import the image file
import thankyouImage from './images/thanks.png'; // Import the image file

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import config from 'react-global-configuration';

var moment = require("moment-timezone");

var dropin = require('braintree-web-drop-in');
var CryptoJS = require("crypto-js");

class CartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        modalMaxHeight: window.innerHeight*0.88,
        cartSelectValue: 0,
        cartModal: false,
        checkOutModal: false,
        noticeMessage: '',
        supportToolStatus: true,
        eMenuCheckout: false,
        eMenuOrder: false,
        eMenuRequest: false,
        checkOutPaymentModal: false,
        noticePaymentMessage: '',
        showGCPayment: false,
        showAddGCOption: false,
        currentGCType: 'gc',
        gcNumber:'',
        gcPINNumber:'',
        validGCList:[],
        creditCardPaymentDisplay: 'block',
        orderTotalAfterTax: parseFloat((this.props.shoppingCart.totalKitchenPrice * 1.06).toFixed(2)),
        loadingPayment: true,
        braintreeProcessing: false,
        paymentToken: '',
        applePayToken: '',
        applePayMerchantIdentifier: '',
        paypalBraintreeReturnURL: '',
        googlePayMerchantId: '',
        paymentProcessing: '',
        paymentError: '',
        selectedTip: 0,
        customTip: 0,
        accordionOpened: true,
        orderTotalAfterTip: 0,
        orderTip: 0,
        completePaymentModal: false,
        receiptMethod: '',
        receiptEmail: '',
        completePaymentNoticeMessage: '',
        curOrderID: '',
    };
  }
  
  async componentDidMount(){
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ////console.log(this.props);

    //console.log(this.props.match.params);

    setTimeout(() => {
      // function code goes here
      this.toggleSupportOption();
    }, 3000);

    this.checkPlatformStatus();

    this.getOrderTotal();

    //this.getUserGPSData();
}

getOrderTotal = () => {
  // Calculate the total before tip
  const curTotalBeforeTip = parseFloat((this.props.shoppingCart.totalKitchenPrice * 1.06).toFixed(2));
  let curTip = 0.00;
  let curTotalAfterTip = curTotalBeforeTip;

  // Determine the correct tip amount
  if (this.state.selectedTip > 0) {
    curTip = parseFloat(this.state.selectedTip);
  }

  if (this.state.customTip > 0) {
    curTip = parseFloat(this.state.customTip);
  }

  // Calculate total after tip
  curTotalAfterTip = parseFloat((curTotalBeforeTip + curTip).toFixed(2));

  // Update the state with the calculated total
  this.setState({ 
    orderTotalAfterTip: curTotalAfterTip, 
    orderTip: curTip, 
  });

  //console.log("total after tip: $" + curTotalAfterTip);

  //return curTotalAfterTip;
}


getUserGPSData = ()=>{
  if (navigator.geolocation) {
    //alert("Your location data is needed to ensure eMenu function porpoly, please grand location permision once you see the pop up notice!");

    navigator.geolocation.getCurrentPosition(
      position => {
        console.log(position.coords.latitude, position.coords.longitude);
      },
      error => {
        console.log(error.message);
      }
    );
  } else {
    console.log('Geolocation is not supported by your browser.');
  }
}

  checkPlatformStatus = async ()=>{

    let curLocation = await localStorage.getItem('curLocation');

    fetch(`${config.get('API_URL')}/orders/platformStatus?curLocation=${curLocation}`,{
      method: 'GET',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      })

    .then((response) => response.json())
    .then(async (res) => {
      //alert('here');
      if(res.success === true){

        //console.log(res);

        await localStorage.setItem('eMenuOrder', res.eMenuOrder);
        await localStorage.setItem('eMenuCheckout', res.eMenuCheckout);
        await localStorage.setItem('eMenuRequest', res.eMenuRequest);


        this.setState({
          eMenuOrder: res.eMenuOrder,
          eMenuCheckout: res.eMenuCheckout,
          eMenuRequest: res.eMenuRequest
        })

        }else{
          console.log(res.message);
        }
      })
      .catch((err)=>{
        console.log(err);
      })
    

  }

  openModal = () => {
    this.setState({ cartModal: true });
  };

  toggleSupportOption = ()=>{
    this.setState({supportToolStatus: this.state.supportToolStatus?false:true})
  }

  closeModal = () => {
    this.setState({ cartModal: false });
    this.props.onClose(); // Call the onClose function passed from the parent component
  };

  showNewCart = ()=>{
    //console.log(this.props.shoppingCart.newCart);
    return this.props.shoppingCart.newCart&&this.props.shoppingCart.newCart.length>0?this.props.shoppingCart.newCart.map((item, index)=>this.rederCartItem(item, true, index)):<div style={{padding: 30}}><img style={{height: '100%', width: '100%'}} src={emptyImage} alt="empty cart" /></div>;
  }

  showKitchenCart = ()=>{
    //console.log(this.props.shoppingCart.completeCart);
    return this.props.shoppingCart.completeCart&&this.props.shoppingCart.completeCart.length>0?this.props.shoppingCart.completeCart.map((item, index)=>this.rederCartItem(item, false, index)):<div style={{padding: 30}}><img style={{height: '100%', width: '100%'}} src={emptyImage} alt="empty cart" /></div>;
  }


  rederCartItem = (item, editable, index)=>{
    //id, name, number, note, price
    let cartItem = <Grid item xs={12} key={index} style={{padding: 12, borderTop:'1px gray solid'}} className="pointer">
                       <Grid container style={{ padding: 10}}>
                        <Grid item container xs={8} direction="column" justify="center">
                            <Grid item className="menuTitle" style={{ fontSize: window.innerWidth < 450 ? (parseInt(this.state.font) + 1) + 'px' : (parseInt(this.state.font) + 4) + 'px' }}>
                                <b>{item.name}</b>
                            </Grid>

                            {editable?<Grid container style={{ fontSize: window.innerWidth < 450 ? (parseInt(this.state.font) + 1) + 'px' : (parseInt(this.state.font) + 4) + 'px'}}>
                              <Grid item xs={2} md={2}>
                                Qty:
                              </Grid>
                              <Grid item xs={1} md={1}>
                                <RemoveCircleOutline style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.adjustQuantity(item.id, item.note, parseInt(item.number)-1).toString()} />
                              </Grid>
                              <Grid item xs={2} md={2} style={{textAlign: 'center'}}>
                                {item.number}
                              </Grid>
                              <Grid item xs={1} md={1}>
                                  <AddCircleOutline style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.adjustQuantity(item.id, item.note, parseInt(item.number)+1).toString()} />
                              </Grid>
                              <Grid item xs={4} md={4} style={{ textAlign: 'right' }}>
                                  <DeleteForever style={{ color: 'gray', cursor: 'pointer'}} onClick={() => this.adjustQuantity(item.id, item.note, '0')} />
                              </Grid>
                            </Grid>: <Grid container style={{ fontSize: window.innerWidth < 450 ? (parseInt(this.state.font) + 1) + 'px' : (parseInt(this.state.font) + 4) + 'px'}}>
                              <Grid item xs={2} md={2}>
                                Qty:
                              </Grid>
                              <Grid item xs={2} md={2} style={{textAlign: 'center'}}>
                                {item.number}
                              </Grid>
                            </Grid>}

                            <Grid item className="menuTitle" style={{ fontSize: window.innerWidth < 450 ? (parseInt(this.state.font) + 1) + 'px' : (parseInt(this.state.font) + 4) + 'px' }}>
                                Note: {item.note}
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ position: 'relative', overflow: 'hidden' }}>
                            <LazyLoadImage
                                effect="blur"
                                width={'100%'} // Adjust the width to make the image bigger
                                placeholderSrc={'https://evergreen-restaurant.com/images/menu/coming.png'}
                                style={{width: '100%', height: '100%', borderRadius: '25px', maxHeight: 180 }}
                                src={`https://evergreen-restaurant.com/images/menu/${item.id}.jpg`}
                            />
                            <div style={{ position: 'absolute', top: '8px', left: '8px', background: 'rgba(255,255,255,0.8)', padding: '2px', borderRadius: '5px' }}>
                                <b>${item.price}</b>
                            </div>
                        </Grid>
                    </Grid>
                    </Grid>;

    return cartItem;
    
  }


  adjustQuantity = async(id, note, text)=>{
    let newText = '';
    let numbers = '0123456789';

    for (let i=0; i < text.length; i++) {
        if(numbers.indexOf(text[i]) > -1 ) {
            newText = newText + text[i];
        }
        else {
            toast('Invalid Number, please enter numbers only.');
            return;
        }
    }


    let curLocation = await localStorage.getItem('curLocation');

    let selectedTable = await localStorage.getItem('selectedTable');

    let cartID = `${curLocation}-${selectedTable}`;

    let finalCart = await localStorage.getItem(cartID); 


    if(finalCart !== null && finalCart !== undefined && finalCart.length !== 0){

      // Decrypt 
      let bytes  = CryptoJS.AES.decrypt(finalCart.toString(), config.get('myCart'));
      finalCart = bytes.toString(CryptoJS.enc.Utf8);

      finalCart = JSON.parse(finalCart);

      for(let i=0; i<finalCart.newCart.length; i++){
        if(finalCart.newCart[i].id === id && finalCart.newCart[i].note === note){
          finalCart.newCart[i].number = text;
           if(parseInt(text)<=0){
             toast(`Success, ${finalCart.newCart[i].name} has been deleted.`);
             delete finalCart.newCart[i];
           }
        }
      }
      
      var find = ',null';
      var find2 = 'null,';
      var find3 = 'null';
      var re = new RegExp(find, 'g');
      var re2 = new RegExp(find2, 'g');
      var re3 = new RegExp(find3, 'g');

      var result = JSON.stringify(finalCart);

      result = result.replace(re, '');
      result = result.replace(re2, '');
      result = result.replace(re3, '');

      // Encrypt 
      result = CryptoJS.AES.encrypt(result, config.get('myCart'));

      await localStorage.setItem(cartID, result);

    }


    this.props.actions.loadShoppingCart(true);

}

toggleCheckOutModal = ()=>{
  this.setState({
    checkOutModal:!this.state.checkOutModal,
    noticeMessage: '',
  })
}


toggleCheckOutPaymentModal = () => {
  this.setState({
    validGCList: [],
    checkOutPaymentModal: !this.state.checkOutPaymentModal,
    noticePaymentMessage: '',
    selectedTip: 0,
    customTip: 0,
    accordionOpened: true,
  }, () => {
    this.init();
    this.getOrderTotal();
  });
}


showCheckoutInfo = async()=>{
  this.toggleShoppingCart();
  this.toggleCheckOutModal();


}

generateGCPayement = ()=>{
  return <Grid item xs={12} md={12}>
    <p>Gift Card <AddCircle style={{fontWeight: 'bold', color: 'green', cursor: 'pointer'}} onClick={()=>this.setState({showAddGCOption: this.state.validGCList.length>=3?false:this.state.showAddGCOption?false:true})}/></p>
    {this.state.validGCList.map((gc)=>{
      return <Grid xs={12} md={12}>
      <Alert icon={<Check fontSize="inherit" />} severity="success">
        <strong>Gift Card: **** **** **** {gc.lastFive}  Balance: ${gc.balance.toFixed(2)}</strong>
        <Box component="span" ml={2}> {/* ml={2} adds a margin-left of 2 spacing units */}
          <Icon color="error" fontSize="inherit" onClick={() => this.removeGC(gc.number)}>
            <Delete/>
          </Icon>
        </Box>
      </Alert>
    </Grid>;
    })}
    {this.state.showAddGCOption?this.showAddGC():null}
  </Grid>;
}

removeGC = (gcNumber)=>{
  let newGCList = [];
  let gcTotal = 0
  for(let i=0; i<this.state.validGCList.length; i++){
    if(this.state.validGCList[i].number != gcNumber){
      newGCList.push(this.state.validGCList[i]);
      gcTotal = parseFloat(gcTotal) + parseFloat(this.state.validGCList[i].balance);
    }
  }

   if(gcTotal >= this.state.orderTotalAfterTip)
      this.setState({creditCardPaymentDisplay: 'none'});
    else
      this.setState({creditCardPaymentDisplay: 'block'});


  this.setState({
    validGCList: newGCList,
  })
}


handleInputChange = (e, field) => {
  this.setState({ [field]: e.target.value });
};


showAddGC = () => {
  return (
    <Grid item xs={12}>
      <RadioGroup
        row
        aria-labelledby="optionButtonOne"
        name="optionButtonOne"
        onChange={(event) => {
          this.setState({
            currentGCType: event.target.value
          });
        }}
      >
        <FormControlLabel
          key={1}
          control={<Radio />}
          label="Physical Gift Card"
          value="gc"
          checked={this.state.currentGCType === 'gc'}
        />
        <FormControlLabel
          key={2}
          control={<Radio />}
          label="E-Gift Card"
          value="egc"
          checked={this.state.currentGCType === 'egc'}
        />
      </RadioGroup>

      {this.state.currentGCType === 'gc' ? (
        <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <TextField
                id="gift-card-number"
                value={this.state.gcNumber}
                label="Gift Card Number"
                variant="filled"
                type="number"
                fullWidth
                onChange={(e) => this.handleInputChange(e, 'gcNumber')}
              />
            </FormGroup>
          </Grid> 
        </Grid>
        <br/>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={this.checkBalanceAction}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => this.setState({ showAddGCOption: false })}
              style={{ backgroundColor: '#d3d3d3', color: '#000' }} // Custom color
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        </>
      ) : (<>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <FormGroup>
              <TextField
                id="gift-card-number"
                value={this.state.gcNumber}
                label="Gift Card Number"
                variant="filled"
                type="number"
                fullWidth
                onChange={(e) => this.handleInputChange(e, 'gcNumber')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormGroup>
              <TextField
                id="pin-number"
                value={this.state.gcPINNumber}
                label="PIN Number"
                variant="filled"
                type="password"
                fullWidth
                onChange={(e) => this.handleInputChange(e, 'gcPINNumber')}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={this.checkBalanceAction}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => this.setState({ showAddGCOption: false })}
              style={{ backgroundColor: '#d3d3d3', color: '#000' }} // Custom color
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        </>
      )}
      <Grid item xs={12}>
        <p>* Up to 3 gift cards per order.</p>
      </Grid>
    </Grid>
  );
};


// showAddGC = ()=>{
//   return <Grid item xs={12} md={12} >
//               <RadioGroup
//                 row
//                 aria-labelledby="optionButtonOne"
//                 name="optionButtonOne"
//                 onChange={(event) => {
//                   this.setState({
//                     currentGCType: event.target.value
//                   });
//                 }} // Handle change
//               >
//                 <FormControlLabel 
//                   key={1}
//                   style={{ fontSize: '3px' }}
//                   control={<Radio />}
//                   label={'Physic Gift Card'}
//                   value={'gc'}
//                   checked={this.state.currentGCType=='gc'}
//                   />

//                 <FormControlLabel 
//                   key={2}
//                   style={{ fontSize: '3px' }}
//                   control={<Radio />}
//                   label={'E-Gift Card'}
//                   value={'egc'}
//                   checked={this.state.currentGCType=='egc'}
//                   />     
//               </RadioGroup>


//         {this.state.currentGCType=='gc'?
//         <Grid item xs={12} md={12}>
//             <Grid item xs={8} md={8}>
//               <FormGroup controlId="formHorizontalTip" onChange={(e)=>{this.setState({gcNumber: e.target.value})}}>
//                 <TextField id="filled-basic" value = {this.state.gcNumber} label="Gift Card Number" variant="filled" />
//               </FormGroup>
//             </Grid>
//              <Grid item xs={2} md={2}><Button bsStyle="info" block onClick={()=>this.checkBalanceAction()}>Apply</Button></Grid>
//              <Grid item xs={2} md={2}><Button block onClick={()=>{this.setState({showAddGCOption: false})}}>Cancel</Button></Grid>
//          </Grid>:
//         <Grid item xs={12} md={12}>
//           <Grid item xs={12} md={5}>
//               <FormGroup controlId="formHorizontalTip" onChange={(e)=>{this.setState({gcNumber: e.target.value})}}>
//               <TextField id="filled-basic" value = {this.state.gcNumber} label="Gift Card Number" variant="filled" />
//               </FormGroup>
//             </Grid>
//              <Grid item xs={12} md={3}>
//               <FormGroup controlId="formHorizontalTip" onChange={(e)=>{this.setState({gcPINNumber: e.target.value})}}>
//               <TextField id="filled-basic" value = {this.state.gcPINNumber} label="PIN Number" variant="filled" />
//               </FormGroup>
//             </Grid>
//              <Grid item xs={2} md={2}><Button bsStyle="info" block onClick={()=>this.checkBalanceAction()}>Apply</Button></Grid>
//              <Grid item xs={2} md={2}><Button block onClick={()=>{this.setState({showAddGCOption: false})}}>Cancel</Button></Grid>
//         </Grid>}
//         <Grid item xs={12} md={12}>* Up to 3 gift cards per order.</Grid>
//       </Grid>;
// }




checkBalanceAction = ()=>{

    let tmpGCList = this.state.validGCList;
          
    for(let i=0; i<tmpGCList.length; i++){
      if(tmpGCList[i].number ==  this.state.gcNumber){
        this.setState({
           gcNumber: '',
           gcPINNumber:'',
         });
        toast(`Oops, this gift card has already been applied.`);
        return;
      }
    }

   let gcType = this.state.currentGCType == 'gc'?'pgc':'egc';

   fetch(`${config.get('API_URL')}/menu/giftcardbalance?type=${gcType}&&ID=${this.state.gcNumber}&&PIN=${this.state.gcPINNumber}`,{
          method: 'GET',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          })

      .then((response) => response.json())
      .then((res) => {

        if(res.success === true){

          if(res.balance > 0){
            tmpGCList.push({
              type: gcType,
              number: this.state.gcNumber,
              pin: this.state.gcPINNumber,
              balance: res.balance,
              lastFive: res.lastfive,
            })
          }else{
            toast(`Oops, there is no available balance on this card.`);
          }

          tmpGCList = tmpGCList.sort((a,b)=>parseFloat(a.balance) - parseFloat(b.balance));

          this.setState({
            validGCList: tmpGCList,
            showAddGCOption: false,
          });

          let gcTotal = 0;
          for(let i=0; i<tmpGCList.length; i++){
            gcTotal = parseFloat(gcTotal) + parseFloat(tmpGCList[i].balance);
          }

          if(gcTotal >= this.state.orderTotalAfterTip)
            this.setState({creditCardPaymentDisplay: 'none'});
          else
            this.setState({creditCardPaymentDisplay: 'block'});

        }else{
           toast(`Oops, ${res.message}`);
        }

         this.setState({
           gcNumber: '',
           gcPINNumber:'',
         });

      })
      .catch((err)=>{
         toast('Error, Internet not available, please try again later.');

    })

 } 

placeOrder = async()=>{
  let curLocation = await localStorage.getItem('curLocation'); 

  let selectedTable = await localStorage.getItem('selectedTable');

  let cartID = `${curLocation}-${selectedTable}`;

  let finalCart = await localStorage.getItem(cartID); 

  let toBeMade = {};

  if(finalCart !== null && finalCart !== undefined && finalCart.length !== 0){

    // Decrypt 
    let bytes  = CryptoJS.AES.decrypt(finalCart.toString(), config.get('myCart'));
    finalCart = bytes.toString(CryptoJS.enc.Utf8);

    finalCart = JSON.parse(finalCart);

    toBeMade = {
      cartID: cartID,
      toBeMadeCart: JSON.stringify(finalCart.newCart),
    };

    finalCart.completeCart = finalCart.completeCart.concat(finalCart.newCart);
    finalCart.newCart = [];

    //console.log(finalCart);

    var result = JSON.stringify(finalCart);

    // Encrypt 
    result = CryptoJS.AES.encrypt(result, config.get('myCart'));

    await localStorage.setItem(cartID, result);

    toast("Success, we will start to prepare the order now!");

    this.props.actions.loadShoppingCart(true);

  }
  
  fetch(`${config.get('API_URL')}/menu/tobemadeorder`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(toBeMade) // Include data in the request body
  })
  .then((response) => response.json())
  .then((res) => {
    //this.toastNotify('here');
    //toast(`${res.message}!`);
    console.log(res.message);
  })
  .catch((err) => {
    //toast(`${err}!`);
    console.log(err);
  })


}


handleQuantity =(qty)=>{
    let newQty = this.state.quantity+qty;

    if(newQty >= 1){

      this.setState({
        quantity: newQty,
        modalTotal: newQty * this.state.modalDishPrice,
      })

    }
  }

  toggleShoppingCart = ()=>{
    this.showNewCart();
    this.setState({
      cartSelectValue: 0,
      cartModal: this.state.cartModal?false:true,
    })
  }

  handleCartChange = (event, newValue)=>{
    this.setState({
      cartSelectValue: newValue,
    });
    

    if(newValue == 0)
      this.showNewCart();
    else
      this.showKitchenCart();

    //console.log(newValue);
  }

  noticeMessageAction = (type)=>{
    if(type == "table"){
      this.serviceAction('Need Check');
      this.setState({
        noticeMessage: <p style={{color: 'green'}}>A server will help you to checkout shortly. Thank You!</p>
      })
    }

    if(type == "frontdesk"){
      this.serviceAction('Customer will go to frontdesk to checkout');
      this.setState({
        noticeMessage: <p style={{color: 'green'}}>Please head to frontdesk to checkout. Thank You!</p>
      })
    }

    if(type == "selfcheckout"){
      this.toggleCheckOutModal();

      this.toggleCheckOutPaymentModal();
      
      // this.setState({
      //   noticeMessage: <p style={{color: 'green'}}>Self checkout is not available for now. Thank You!</p>
      // })
    }

  }


  serviceAction= async(request, notice)=>{
    //console.log(request);

    let curLocation = await localStorage.getItem('curLocation'); 

    let selectedTable = await localStorage.getItem('selectedTable');


    if(!curLocation || !selectedTable || !request){
      toast(`Woops, something went wrong! Please try again later.`);
      return;
    }

    let store = '';

    if(curLocation == 1){
      store = 'evergreen';
    }else if(curLocation == 2){
      store = 'downtown';
    }

    fetch(`${config.get('API_URL')}/menu/serviceAction?store=${store}&table=${selectedTable}&request=${request}`,{
        method: 'GET',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        })

    .then((response) => response.json())
    .then((res) => {
      //this.toastNotify('here');
      if(notice != false){
        toast(`${res.message}!`);
      }

    })
    .catch((err)=>{
      toast(`${err}!`);
    })


  }


  createPaymentDropIn = () => {

    let gcTotal = 0;
    for(let i=0; i<this.state.validGCList.length; i++){
      gcTotal = parseFloat(gcTotal) + parseFloat(this.state.validGCList[i].balance);
    }


    if(gcTotal >= this.state.orderTotalAfterTip)
      this.setState({creditCardPaymentDisplay: 'none'});
    else
      this.setState({creditCardPaymentDisplay: 'block'});



    dropin.create({
      //authorization: this.state.applePayToken,
      authorization: this.state.paymentToken,
      container: '#dropin-container',
      dataCollector: {
        kount: true, // Required if Kount fraud data collection is enabled
        //paypal: true // Required if PayPal fraud data collection is enabled
      },
      card: {
          // cardholderName: {
          //     required: false
          // }
      },
      // paymentOptionPriority: ['card','paypal','applePay', 'googlePay', 'venmo'],
      // paypal: { flow: 'vault'},
      // applePay: {
      //     displayName: 'Evergreen Restaurant',
      //     paymentRequest: {
      //       total: {
      //           label: 'Evergreen Restaurant',
      //           amount: this.state.orderTotalAfterTip
      //         }
      //     }
      // },
      // googlePay:{
      //     merchantId:this.state.googlePayMerchantId,
      //     transactionInfo:{
      //       totalPriceStatus: 'FINAL',
      //       totalPrice: this.state.orderTotalAfterTip,
      //       currencyCode: 'USD'
      //     }
      // },
      // venmo: {
      //   allowNewBrowserTab: false
      // },
      translations: {
          //payWithCard: '$'+this.state.orderTotalAfterTip,
          payingWith: 'You are paying with {{paymentSource}}',
      }
    },  (createErr, instance) => {

      if(instance){
        this.instance = instance;
        this.setState({loadingPayment: false});
      }

      if(createErr){
        console.log(createErr);
      }
    });
}

init = async () => {
  
  this.initPaymentToken();

}


initPaymentToken = async ()=>{
    //toast('Loading secure payment interface...');

    this.setState({
      loadingPayment: true,
      paymentToken: null,
    });

    let curLocation = await localStorage.getItem('curLocation');

    // if(curLocation == undefined)
    //   curLocation = '1';

    await fetch(`${config.get('API_URL')}/payment/token?orderTime=2020-04-11 16:55:50&curLocation=${curLocation}`,{
        method: 'GET',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        })

    .then((response) => response.json())
    .then((res) => {

      if(res.success === true){
        //console.log(res);

        this.setState({
          showGCPayment: true,
          paymentToken: res.token,
          applePayToken: res.appleToken,
          applePayMerchantIdentifier: res.applePayMerchantIdentifier,
          paypalBraintreeReturnURL: res.paypalBraintreeReturnURL,
          googlePayMerchantId: res.googlePayMerchantId,
        },
        () => {
            this.createPaymentDropIn();
        })


      }else{
         toast(`Oops, ${res.message}`);
         this.setState({paymentError: `Oops, ${res.message}`});
         //scroll.scrollTo(220);
      }

    })
    .catch((err)=>{
       toast('Error, Internet not available, please try again later.');
       this.setState({paymentError: <span>Error, Internet not available, please try again later.</span>});
       //scroll.scrollTo(220);
    })

  

}


makePayment = async ()=>{
  toast("Processing payment, please wait ......", "info");

  //let validateGCList = await this.validateGiftCard(this.state.validGCList);

  this.setState({
    braintreeProcessing: true,
    paymentProcessing: "Processing payment, please wait......",
    paymentError: '',
  });

  let curLocation = await localStorage.getItem('curLocation'); 

  let selectedTable = await localStorage.getItem('selectedTable');

  let instructionsString = "";

  if(window.innerWidth<700){
    instructionsString = "mDineIn-Order";
  }else{
    instructionsString = "DineIn-Order";
  }

  let orderData = {
          'userid': '0',
          'name': selectedTable,
          'phone': '',
          'amount': this.props.shoppingCart.totalKitchenPrice, //order Subtotal before discount
          'method': "dinein",
          'addressone': "",
          'addresstwo': "",
          'city': "",
          'zip': "",
          'note': instructionsString,  
          'cart': this.props.shoppingCart.completeCart, 
          'delivery': '0',  
          'orderTotal': this.state.orderTotalAfterTip,  //final total amount beening charged
          'tax': parseFloat((this.props.shoppingCart.totalKitchenPrice*0.06).toFixed(2)),
          'discount': '0',
          'tip': this.state.orderTip,
          'coupon': '',
          'giftcardusage': this.state.validGCList,
          'SMSNotice': 0,
        };

     let gcTotal = 0;
     for(let i =0; i<this.state.validGCList.length; i++){
       gcTotal = parseFloat(gcTotal) + parseFloat(this.state.validGCList[i].balance);
     }

     if(gcTotal >= this.state.orderTotalAfterTip){
       this.processPureGCOrder(orderData, "", this.state.orderTime, '');

     }else{

     await this.instance.requestPaymentMethod()
     .then((result)=> {
           // Submit payload.nonce to your server
        //console.log(result);
        let nonce = result.nonce;
        let deviceData = result.deviceData;

        // console.log(nonce);
        // console.log(deviceData);

        fetch(`${config.get('API_URL')}/payment/transaction`,{
            method: 'POST',
            headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              amount: orderData.orderTotal,
              nonce: nonce,
              data: JSON.stringify(orderData),
              userid: orderData.userid,
              useremail: '',
              deviceData: deviceData,
              firebasetoken: null,
              orderTime: moment(new Date()).tz('America/Detroit').format("YYYY-MM-DD HH:mm:ss"),
              curLocation: curLocation,
            })
            })

        .then((responsePayment) => responsePayment.json())
        .then((result) => {

          //console.log(result);

          this.setState({
            paymentProcessing: "",
            braintreeProcessing: false,
          });

          if(result.success === true){
            this.setState({
              paymentError: '',
              curOrderID: result.orderid,
            });
            this.toggleCheckOutPaymentModal();
            this.toggleCompletePaymentModal();
            toast(result.message);

            //{
//     "success": true,
//     "message": "***This is a test order*** Thank You for the Payment! Your order(aye3hzef) has been completed now.",
//     "orderid": "aye3hzef",
//     "paymentInfo": "1111",
//     "paymentImgUrl": "https://assets.braintreegateway.com/payment_method_logo/visa.png?environment=sandbox",
//     "paymentName": "Credit Card"
// }

            this.adjustDineInOrderStatus(result.orderid, result.paymentInfo, orderData.tip, orderData.giftcardusage);

          }else{
            this.setState({paymentError: <Alert icon={<Warning fontSize="inherit" />} severity="error">
                <strong>Payment Process Fail! Please re-enter card information and try again.</strong>
              </Alert>});
            //scroll.scrollTo(420);
            //${result.message}
            toast(`Payment Process Fail! Please re-enter card information and try again.`);
            
            //this.setState({applePayToken: null});
            //this.initPaymentToken();
          }

        })
        .catch((err)=>{
            this.setState({
              paymentError: <Alert icon={<Warning fontSize="inherit" />} severity="error"><strong>Error, Internet not available, please try again later.</strong></Alert>,
              paymentProcessing: "",
              braintreeProcessing: false,
            });
           //scroll.scrollTo(420);
           toast('Error, Internet not available, please try again later.');
        })

  })
  .catch((e)=>{

      toast(`Oops, ${e.message}, Please choose your payment method and try again.`);
      this.setState({
        paymentError: <Alert icon={<Warning fontSize="inherit" />} severity="error"><strong>Oops, {e.message}, Please choose your payment method and try again.</strong></Alert>,
        paymentProcessing: "",
        braintreeProcessing: false,
      });
      //scroll.scrollTo(420);
    }
    );
}
  

}


  handleTipChange = (event) => {
    this.setState({
      selectedTip: event.target.value
    }, ()=>this.getOrderTotal());

    if (event.target.value !== 'custom') {
      this.setState({
        customTip: 0,
      }, ()=>this.getOrderTotal());
    }
  };

  handleCustomTipChange = (event) => {
    let value = event.target.value;
    
    // Remove leading zeros
    value = value.replace(/^0+/, '');

    this.getOrderTotal();
    
    // Check if the value is valid
    if (!value || isNaN(value) || parseFloat(value) <= 0) {
      toast('Tip amount must be a positive number');
      this.setState({
        customTip: value, // Still update the state to reflect the input
      });
      return;
    }
    
    // Update the state with a valid tip amount
    this.setState({
      customTip: value,
      selectedTip: 'custom',
    }, ()=>this.getOrderTotal());
  };


  adjustDineInOrderStatus = async(paymentId, paymentInfo, orderTip, giftcardusage)=>{

      let curLocation = await localStorage.getItem("curLocation");

      let selectedTable = await localStorage.getItem('selectedTable');

      let cartID = `${curLocation}-${selectedTable}`;

      let finalCart = await localStorage.getItem(cartID); 
  
      // Decrypt 
      let bytes  = CryptoJS.AES.decrypt(finalCart.toString(), config.get('myCart'));
      finalCart = bytes.toString(CryptoJS.enc.Utf8);
  

      let data = {
        curLocation: curLocation,
        cartID: cartID,
        finalCart: JSON.parse(finalCart),
        orderStatus: '3',
        paymentId: paymentId,
        orderTip: orderTip,
        giftcardusage: giftcardusage,
        paymentInfo: paymentInfo
      }

      fetch(`${config.get('API_URL')}/orders/adjustDineInOrdersAfterPayment`,{
          method: 'POST',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          })
      .then((response) => response.json())
      .then((res) => {
        //toast(res);
        //console.log(res);
        this.serviceAction('Customer self-checkout complete.', false);
        
      })
      .catch((err)=>{
        toast(err);
      })
  }


  toggleCompletePaymentModal = ()=>{
    this.setState({
      completePaymentModal: !this.state.completePaymentModal,
      receiptMethod: '',
      receiptEmail: '',
      completePaymentNoticeMessage: '',
    });
  }


  sendReceipt = (method) => {
    this.setState({ receiptMethod: method});

    if(method == 'print'){
      this.serviceAction('Need Receipt!', false); 
      toast('We will bring the receipt to you soon.');
      this.toggleCompletePaymentModal();
      return;
    }


    if (method === 'email' && this.state.receiptEmail === '') {
      this.setState({ completePaymentNoticeMessage: 'Please enter a valid email address.' });
    } else {
      this.setState({ completePaymentNoticeMessage: 'Receipt will be sent shortly.' });
      // Logic to handle sending receipt
      fetch(`${config.get('API_URL')}/orders/receipt`,{
          method: 'POST',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            jsonData: null,
            transactionID: this.state.curOrderID,
            paymentImgUrl: null,
            paymentInfo: null,
            orderTime: null,
            useremail: this.state.receiptEmail,
          })
          })

      .then((response) => response.json())
      .then((res) => {
        //alert('here');
        if(res.success === true){
          toast('Receipt sent succefully!');
          this.toggleCompletePaymentModal();
        }else{
          toast(res.message);
        }
      })
      .catch((err)=>{
        console.log(err);
      })
      

    }
  };
  

  render() {

    return (

        <>
         {(localStorage.getItem('selectedTable') && this.state.eMenuOrder)?<Badge
                    badgeContent={(this.props.shoppingCart.totalNewItem||this.props.shoppingCart.totalKitchenItem)?(this.props.shoppingCart.totalKitchenItem+this.props.shoppingCart.totalNewItem):0} // Replace 5 with your desired number
                    color="primary"
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    style={{ position: 'fixed', top: 190, right: 40 }}
                >
                    <Fab
                        color="inherit"
                        variant="extended"
                        size={window.innerWidth<450?'small':'large'}
                        onClick={() => this.toggleShoppingCart()}
                    >
                        <ShoppingCartCheckout />
                    </Fab>
        </Badge>:null}

        {(localStorage.getItem('selectedTable') && this.state.eMenuRequest)?<div style={{ display: 'flex', justifyContent: 'center' }}>
                <SpeedDial
                  FabProps={{ color: 'inherit', variant: 'extended', size: window.innerWidth < 450 ? 'small' : 'large' }}
                  ariaLabel="Tool Options"
                  sx={{ position: 'fixed', bottom: 30, right: 40 }}
                  icon={<RoomService />}
                  open={this.state.supportToolStatus}
                  direction={'up'}
                  onClick={this.toggleSupportOption}
                >
                  {/* SpeedDialAction components go here */}
                  <SpeedDialAction
                    icon={<LocalDrink />}
                    key={'Add Water'}
                    tooltipTitle={'Add Water'}
                    tooltipPlacement={'left'}
                    tooltipOpen
                    onClick={() => {
                      this.serviceAction('Add Water');
                      //this.toggleMenuOption();
                      //history.push(`/store/${this.state.store}/category/${item.CATEGORY}/font/${this.state.font}`);
                    }}
                  />

                  <SpeedDialAction
                    icon={<Restaurant />}
                    key={'Need Utensil'}
                    tooltipTitle={'Need Utensil'}
                    tooltipPlacement={'left'}
                    tooltipOpen
                    style={{marginBottom:20}}
                    onClick={() => {
                      this.serviceAction('Need Utensil');
                      //this.toggleMenuOption();
                      //history.push(`/store/${this.state.store}/category/${item.CATEGORY}/font/${this.state.font}`);
                    }}
                  />

                  <SpeedDialAction
                    icon={<ReceiptLong />}
                    key={'Request Check'}
                    tooltipTitle={'Request Check'}
                    tooltipPlacement={'left'}
                    tooltipOpen
                    style={{marginBottom:20}}
                    onClick={() => {
                      this.serviceAction('Request Check');
                      //this.toggleMenuOption();
                      //history.push(`/store/${this.state.store}/category/${item.CATEGORY}/font/${this.state.font}`);
                    }}
                  />

                  <SpeedDialAction
                    icon={<ContactSupport />}
                    key={'Need Service'}
                    tooltipTitle={'Need Service'}
                    tooltipPlacement={'left'}
                    tooltipOpen
                    style={{marginBottom:20}}
                    onClick={() => {
                      this.serviceAction('Need Service');
                      //this.toggleMenuOption();
                      //history.push(`/store/${this.state.store}/category/${item.CATEGORY}/font/${this.state.font}`);
                    }}
                  />

                  {/* <SpeedDialAction
                    icon={<QuestionAnswer />}
                    key={'Smart Chat'}
                    tooltipTitle={'AI Chat'}
                    tooltipPlacement={'left'}
                    tooltipOpen
                    style={{marginBottom:20}}
                    onClick={() => {
                      //this.toggleMenuOption();
                      //history.push(`/store/${this.state.store}/category/${item.CATEGORY}/font/${this.state.font}`);
                    }}
                  /> */}
                </SpeedDial>
              </div>:null}
        <Modal open={this.state.cartModal} onClose={this.toggleShoppingCart}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: window.innerWidth < 600 ? window.innerWidth : 600,
            bgcolor: '#e5e4da',
            border: '0px solid #000',
            boxShadow: 24,
            padding: 0,
            borderRadius: '25px',
            outline: 'none',
            maxHeight: this.state.modalMaxHeight,
            overflow: 'auto',
          }}
        >
          <Container style={{ padding: 0 }} className="pointer">
            <Tabs
              value={this.state.cartSelectValue}
              onChange={this.handleCartChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full-width tabs"
              style={{ position: 'sticky', top: 0, zIndex: 3, background: '#e5e4da' }}
            >
              <Tab label={`New Cart(${this.props.shoppingCart.totalNewItem || 0})`} />
              <Tab label={`In The Kitchen(${this.props.shoppingCart.totalKitchenItem || 0})`} />
            </Tabs>

            {/* Render content based on selected tab */}
            {this.state.cartSelectValue == 0?this.showNewCart():this.showKitchenCart()}


            {/* Place Order button */}
            {this.props.shoppingCart.totalNewPrice > 0 && this.state.cartSelectValue === 0 && (
              <Grid item xs={12} md={12} style={{ position: 'sticky', bottom: 10, zIndex: 3, width: '100%', paddingLeft: 10, paddingRight: 10 }}>
                <Fab variant='extended' style={{ color: 'black', marginLeft: 'auto', marginRight: 'auto', opacity: 1, width: '100%' }} onClick={this.placeOrder}>
                  <b>Place Order - ${this.props.shoppingCart.totalNewPrice || 0.00}</b>
                </Fab>
              </Grid>
            )}

            {this.props.shoppingCart.totalKitchenPrice > 0 && this.state.cartSelectValue === 1 && (
              <Grid item xs={12} md={12} style={{ position: 'sticky', bottom: 10, zIndex: 3, width: '100%', paddingLeft: 10, paddingRight: 10 }}>
                <Fab variant='extended' style={{ color: 'black', marginLeft: 'auto', marginRight: 'auto', opacity: 1, width: '100%' }} onClick={this.showCheckoutInfo}>
                  <b>Checkout (Subtotal ${this.props.shoppingCart.totalKitchenPrice || 0.00})</b>
                </Fab>
              </Grid>
            )}
          </Container>
        </Box>
      </Modal>


      <Modal open={this.state.checkOutModal} onClose={this.toggleCheckOutModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: window.innerWidth < 600 ? window.innerWidth : 600,
            bgcolor: '#e5e4da',
            border: '0px solid #000',
            boxShadow: 24,
            padding: 0,
            borderRadius: '25px',
            outline: 'none',
            maxHeight: this.state.modalMaxHeight,
            overflow: 'auto',
          }}
        >
          <Container style={{padding:20}}>
            
            <Grid item xs={12} md={12}>
              <p>Please choose your checkout method:</p>
              <hr/>
            </Grid>
            
            <Grid container spacing={2} style={{justify:"center", alignItems:"center"}}>
              <Grid item xs={4} md={4}>
                <Button variant="outlined" onClick={()=>this.noticeMessageAction('table')}>Table Checkout</Button>
              </Grid>

              <Grid item xs={4} md={4}>
                <Button variant="outlined" onClick={()=>this.noticeMessageAction('frontdesk')}>Frontdesk Checkout</Button>
              </Grid>

              <Grid item xs={4} md={4}>
                <Button variant="outlined" onClick={()=>this.noticeMessageAction('selfcheckout')} disabled={!this.state.eMenuCheckout}>Self Checkout</Button>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <hr/>
              {this.state.noticeMessage}
            </Grid>
          </Container>
        </Box>
      </Modal>


      {/* this.state.checkOutPaymentModal */}
      <Modal open={this.state.checkOutPaymentModal} onClose={this.toggleCheckOutPaymentModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: window.innerWidth < 600 ? window.innerWidth : 600,
            bgcolor: '#e5e4da',
            border: '0px solid #000',
            boxShadow: 24,
            padding: 0,
            borderRadius: '25px',
            outline: 'none',
            maxHeight: this.state.modalMaxHeight,
            overflow: 'auto',
          }}
        >
          <Container style={{padding:20}}>

            <Grid item xs={12} md={12}>

              <p>Enter your payment method(Total: ${parseFloat(this.props.shoppingCart.totalKitchenPrice*1.06).toFixed(2)}{this.state.orderTip>0?' + Tip: $'+this.state.orderTip:''})</p>
              <hr/>
            </Grid>


            <Accordion expanded={this.state.accordionOpened} onChange={()=>{this.setState({accordionOpened: !this.state.accordionOpened})}}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ width: '40%', flexShrink: 0 }}>
                      1. Add a tip
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>100% tip will goes to your server</Typography>

              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <List sx={{p: 0,width: '100%',borderRadius: 2,border: '1px solid',borderColor: 'divider',backgroundColor: 'background.paper',}} aria-label="tip selection">
                  <RadioGroup value={this.state.selectedTip} onChange={this.handleTipChange}>
                    <ListItem>
                      <FormControlLabel value={parseFloat(this.props.shoppingCart.totalKitchenPrice * 0.15).toFixed(2)} control={<Radio />} label={`15%($${parseFloat(this.props.shoppingCart.totalKitchenPrice * 0.15).toFixed(2)})`} />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                      <FormControlLabel value={parseFloat(this.props.shoppingCart.totalKitchenPrice * 0.18).toFixed(2)} control={<Radio />} label={`18%($${parseFloat(this.props.shoppingCart.totalKitchenPrice * 0.18).toFixed(2)})`} />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                      <FormControlLabel value={parseFloat(this.props.shoppingCart.totalKitchenPrice * 0.20).toFixed(2)} control={<Radio />} label={`20%($${parseFloat(this.props.shoppingCart.totalKitchenPrice * 0.20).toFixed(2)})`} />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label="Custom"
                        checked={this.state.selectedTip === 'custom'}
                      />
                      {this.state.selectedTip === 'custom' && (
                        <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Enter tip amount"
                        value={this.state.customTip}
                        onChange={this.handleCustomTipChange}
                        sx={{ ml: 2 }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                      )}
                    </ListItem>
                  </RadioGroup>
                </List>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={!this.state.accordionOpened} onChange={()=>{this.setState({accordionOpened: !this.state.accordionOpened})}}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ width: '40%', flexShrink: 0 }}>
                  2. Payment information
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Total: ${this.state.orderTotalAfterTip}</Typography>

              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                    <Grid container spacing={2} style={{justify:"center", alignItems:"center"}}>
                    <Grid item xs={12} md={12} >
                      <font size="4" color="red"><b>{this.state.paymentError}</b></font> 
                    </Grid>
                    {this.state.showGCPayment?this.generateGCPayement():null}
                    <Grid item xs={12} md={12} id="dropin-container" style={{display: this.state.creditCardPaymentDisplay}}></Grid>
                    

                    <Grid item xs={12} md={12} style={{marginTop: 20}} className="text-center">
                      <font color="green" size="3">{this.state.paymentProcessing}</font>
                    </Grid>

                    <Grid item xs={12} md={12} style={{marginTop: 0}}>

                    <Button
                        variant="contained"
                        fullWidth
                        onClick={this.state.loadingPayment
                          ? () => { toast("Loading secure payment interface, please wait...") }
                          : (this.state.braintreeProcessing
                            ? () => { toast("Processing payment, please wait...") }
                            : this.makePayment)
                        }
                        style={this.state.loadingPayment
                          ? { backgroundColor: '#FFEB3B', color: '#000' } // Yellow background for loading
                          : (this.state.braintreeProcessing
                            ? { backgroundColor: '#FF5722', color: '#FFF' } // Orange background for processing
                            : { backgroundColor: '#4CAF50', color: '#FFF' }) // Green background for default state
                        }
                        disabled={this.state.loadingPayment || this.state.braintreeProcessing}
                      >
                        {this.state.loadingPayment
                          ? "Loading Secure Payment Interface..."
                          : (this.state.braintreeProcessing
                            ? "Processing Payment, Please Wait..."
                            : "Submit Payment")
                        }
                      </Button>




                      {/* <AwesomeButton type={(this.state.loadingPayment||this.state.braintreeProcessing)?"disabled":"whatsapp"} className="col-xs-12 col-md-12" onPress={this.state.loadingPayment?()=>{this.toastNotify("Loading secure payment interface, please wait...", "info")}:(this.state.braintreeProcessing?()=>{this.toastNotify("Processing payment, please wait...", "info")}:this.makePayment)}>
                        {this.state.loadingPayment?"Loading Secure Payment Interface...":(this.state.braintreeProcessing?"Processing Payment, Please Wait...":"Place Your Order")}
                      </AwesomeButton> */}
                      <Grid item xs={12} md={12} style={{marginTop: 10, marginBottom: 40}} className="text-center">
                        By placing the order, I agree to Evergreen Oriental Inc's <a href="https://evergreen-restaurant.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy agreement</a> and <a href="https://evergreen-restaurant.com/terms-of-service.pdf" target="_blank" rel="noopener noreferrer">Terms of use </a>.
                      </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12} md={12}>
                  <hr/>
                  {this.state.noticeMessage}
                </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>


            
            
          </Container>
        </Box>
      </Modal>


      {/* this.state.completePaymentModal */}
      <Modal open={this.state.completePaymentModal} onClose={this.toggleCompletePaymentModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: window.innerWidth < 600 ? window.innerWidth : 600,
            bgcolor: '#e5e4da',
            border: '0px solid #000',
            boxShadow: 24,
            padding: 0,
            borderRadius: '25px',
            outline: 'none',
            maxHeight: this.state.modalMaxHeight,
            overflow: 'auto',
          }}
        >
          <Container style={{padding:20}}>
            <Grid item xs={12} md={12}>
             <img src={thankyouImage} alt="Thank You" style={{ width: '80%', margin: '0 auto', display: 'block' }} />
              <p>Payment Complete!</p>
              <p>How would you like to receive your receipt?</p>
              <hr/>
            </Grid>
            
            <Grid container spacing={2} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <Grid item xs={6} md={4}>
                <Button variant="outlined" fullWidth onClick={() => this.setState({ receiptMethod: 'email' })} startIcon={<Email />}>Email Receipt</Button>
              </Grid>

              <Grid item xs={6} md={4}>
                <Button variant="outlined" fullWidth onClick={() => this.sendReceipt('print')}  startIcon={<Print />}>Print Receipt</Button>
              </Grid>
            </Grid>
            
            {this.state.receiptMethod === 'email' && (
             <>
              <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Enter your email"
                  value={this.state.receiptEmail}
                  onChange={(e) => this.setState({ receiptEmail: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                <Button variant="contained" fullWidth onClick={() => this.sendReceipt('email')} startIcon={<Outbox />}>Send Email</Button>
              </Grid>
            </>
            )}

            <Grid item xs={12} md={12} style={{marginTop: 20}}>
              <hr/>
              {this.state.completePaymentNoticeMessage}
            </Grid>
          </Container>
        </Box>
      </Modal>

      </>
    );
  }
}


//Inject success and all action creators (actionCreators, ...) as actions
function mapStateToProps(state) {
//console.log(state.cart.shoppingCart);
return { shoppingCart: state.cart.shoppingCart}
}

function mapDispatchToProps(dispatch) {
return { actions: bindActionCreators(actionCreators, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartModal)
  
  
  // export default withParams(App);
