import React, { Component } from 'react'
import { 
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Fab
 } from '@mui/material';
import {
  useParams,
} from "react-router-dom";
import config from 'react-global-configuration';
import { VerticalAlignTop, Share, ZoomIn, ZoomOut } from '@mui/icons-material';


import history from '../history' ;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class SetTable extends Component {

constructor(props) {
    super(props);

   
    this.state = {
        tableNumber: '',
        store: '',
    };
  }




  async componentDidMount(){
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ////console.log(this.props);

    //console.log(this.props.match.params);

    if(this.props.params !== undefined){

       const { table }  = this.props.params;

       const { store }  = this.props.params;

       this.setState({
            tableNumber: table,
            store: store,
         });

      let key = `${store}-table`;

       await localStorage.setItem(key, table);

       this.jumpToDowntown();
       
     }
  }

  
  jumpToDowntown = ()=>{

    //alert(`Successfully set table to ${this.state.tableNumber}, jump to Home Page now!`);

    history.push(`/store/${this.state.store}/landing`);

  }



render() {
  return (

    <Container>

    </Container>

  );
}

}
export default withParams(SetTable);
