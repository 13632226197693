// store.js
import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk"; // Import thunk from redux-thunk
import { connectRouter, routerMiddleware } from "connected-react-router";
import history from './history';
import * as reducers from "./reducers";

// Apply middleware for intercepting and dispatching navigation actions
const middleware = [
  thunk,
  routerMiddleware(history)
  // Add more middleware as needed
];

// Combine reducers
const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history)
});

// Create Redux store with middleware
const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);

export default store;
