// SocketListener.js
import React, { useEffect } from 'react';
import { connect } from 'react-redux'; // Import connect HOC
import socket from './socket'; // Import your socket service
import * as actionCreators from '../Actions/index';
import { bindActionCreators } from 'redux'; // Import bindActionCreators


const SocketListener = (props) => {
  useEffect(() => {
    socket.connect();

    socket.on('loadcart', message => {
      console.log('socket loadcart');
      props.actions.getLatestCartforThisTable();
    });

    socket.on('updateCart', cartData => {
      console.log('socket updateCart');
      props.actions.saveLatestCartData(cartData);
    });

    // Clean up event listeners
    return () => {
      socket.off('loadcart');
      socket.off('updateCart');
    };
  });

  return null; // SocketListener component doesn't render anything
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actionCreators, dispatch) };
}

export default connect(null, mapDispatchToProps)(SocketListener);
